<template>
  <div>
    <CRow>
      <CCol sm="6">
        <CCard>
          <CCardHeader> Topup Saldo </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="onSubmit()">
              <CSelect
                v-model="form.bank"
                :value.sync="form.bank"
                :options="banks"
                :invalid-feedback="validator.bank"
                :is-valid="validator.bank ? false : true"
              />

              <CInput
                type="number"
                placeholder="Masukkan Nominal Topup"
                v-model="form.amount"
                :invalid-feedback="validator.amount"
                :is-valid="validator.amount ? false : true"
              />
              <CButton color="success" type="submit">
                <CIcon name="cil-level-up" />
                Topup Saldo
              </CButton>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol sm="6">
        <CCard>
          <CCardHeader> Jadwal Cut Off </CCardHeader>
          <CCardBody>
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Bank</th>
                  <th>Jam Aktif</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>BCA</td>
                  <td>03:00 - 22:00</td>
                </tr>
              </tbody>
            </table>
            <p>
              Transfer diluar jam aktif akan diproses esok hari pada jam kerja.
            </p>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CCard>
      <CCardHeader>
        Riwayat Deposit
        <div class="card-header-actions"></div>
      </CCardHeader>
      <CCardBody>
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          :totalRows="totalRecords"
          :isLoading.sync="isLoading"
          :pagination-options="{
            enabled: true,
            perPageDropdown: [10, 20, 30, 50, 100],
            dropdownAllowAll: false,
          }"
          :rows="rows"
          :columns="columns"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'updated_at'">
              <span>
                {{ timestampModify(props.row.updated_at) }}
              </span>
            </span>
            <span v-else-if="props.column.field == 'ref_id'">
              <span>
                <CButton
                  color="primary"
                  size="sm"
                  @click="openInvoice(props.row)"
                >
                  <CIcon name="cil-file" />
                  Detail
                </CButton>
              </span>
            </span>
            <span v-else-if="props.column.field == 'amount'">
              <span>
                {{ props.row.amount | currency }}
              </span>
            </span>
            <span v-else-if="props.column.field == 'expired_at'">
              <span>
                {{ timestampModify(props.row.expired_at) }}
              </span>
            </span>
            <span v-else-if="props.column.field == 'bank_account_name'">
              <span>
                {{ props.row.bank_account_name }} <br />
                {{ props.row.bank_account_number }}
              </span>
            </span>
          </template>
        </vue-good-table>
      </CCardBody>
    </CCard>

    <CModal
      alignment="center"
      title="Deposit"
      :closeOnBackdrop="false"
      :show.sync="modalInvoice"
    >
      <CRow v-if="modalInvoiceDetail.status == 'pending'">
        <CCol class="text-center">
          <p>
            Untuk menyelesaikan transaksi, silahkan lakukan pembayaran ke
            rekening berikut:
          </p>
          <h5>
            {{ modalInvoiceDetail.bank_name }}

            {{ modalInvoiceDetail.bank_account_number }}
            <CButton
              color="link"
              variant="outline"
              size="sm"
              @click="copyData(modalInvoiceDetail.bank_account_number)"
            >
              copy
              <CIcon name="cil-copy" />
            </CButton>
          </h5>
          <p>a/n {{ modalInvoiceDetail.bank_account_name }}</p>
          <h4>
            Nominal: {{ modalInvoiceDetail.amount | currency }}
            <CButton
              color="link"
              variant="outline"
              size="sm"
              @click="copyData(modalInvoiceDetail.amount)"
            >
              copy
              <CIcon name="cil-copy" />
            </CButton>
          </h4>
          <p>
            Pembayaran harus dilakukan paling lambat
            {{ timestampModify(modalInvoiceDetail.expired_at) }}
          </p>
          <hr />
          <p>
            Nominal transfer harus sama dengan nominal yang tertera, yaitu
            {{ modalInvoiceDetail.amount | currency }}
          </p>
          <p>
            Saldo akan masuk keseluruhan, tidak ada potongan biaya administrasi
          </p>
        </CCol>
      </CRow>
      <CRow v-if="modalInvoiceDetail.status == 'success'">
        <CCol>
          <CAlert color="success">
            <h4>
              <CIcon name="cil-check-circle" />
              Deposit berhasil
            </h4>
          </CAlert>
        </CCol>
      </CRow>
      <CRow v-if="modalInvoiceDetail.status == 'failed'">
        <CCol>
          <CAlert color="danger">
            <h4>
              <CIcon name="cil-ban" />
              Deposit gagal
            </h4>
          </CAlert>
        </CCol>
      </CRow>

      <template #footer>
        <CButton @click="modalInvoice = false" color="danger" variant="outline">
          Tutup
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import axios from "../../apis/api";

import moment from "moment";
import "moment/locale/id";

export default {
  name: "Breadcrumbs",
  components: {
    VueGoodTable,
  },
  data() {
    return {
      selectedOption: "",
      banks: [
        {
          value: "",
          label: "Pilih Bank",
        },
        {
          value: "bca",
          label: "Transfer Bank BCA",
        },
      ],
      modalInvoice: false,
      modalInvoiceDetail: {},
      form: {
        amount: "",
        bank: "",
      },
      validator: {
        amount: "",
        banks: "",
      },
      isLoading: false,
      columns: [
        {
          label: "Invoice",
          field: "ref_id",
        },
        {
          label: "Nominal",
          field: "amount",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Bank",
          field: "bank_name",
        },
        {
          label: "Rekening Tujuan",
          field: "bank_account_name",
        },
        {
          label: "Tenggat Waktu",
          field: "expired_at",
        },
        {
          label: "Tanggal",
          field: "updated_at",
        },
      ],
      totalRecords: 0,
    };
  },
  computed: {
    rows() {
      return this.$store.state.deposit_list;
    },
    serverParams() {
      return this.$store.state.deposit_list_param;
    },
  },
  methods: {
    async copyData(data) {
      await navigator.clipboard.writeText(data);
      this.$notify({
        text: "Berhasil disalin",
        type: "success",
      });
    },
    async openInvoice(data) {
      this.modalInvoice = true;
      this.modalInvoiceDetail = data;
    },
    async onSubmit() {
      let loader = await this.$loading.show();
      this.validator.amount = "";
      this.validator.bank = "";
      try {
        const { data } = await axios.post("/balance", {
          amount: this.form.amount,
          bank: this.form.bank,
        });
        await this.$notify({
          type: "success",
          text: "Silahkan melakukan transfer nominal dengan kode unik",
        });
        this.openInvoice(data.data);
        this.loadItems();
      } catch (error) {
        if (error.response.data) {
          const errors = error.response.data.errors;
          if (errors.amount) {
            this.validator.amount = errors.amount.join(" & ");
          }
          if (errors.bank) {
            this.validator.bank = errors.bank.join(" & ");
          }
          if (error.response.data.message) {
            await this.$notify({
              type: "error",
              text: error.response.data.message,
            });
          }
        }
      }
      await loader.hide();
    },
    timestampModify(timestamp) {
      return moment(timestamp).format("DD MMMM YYYY HH:mm:ss");
    },
    updateParams(newProps) {
      let serverParams = Object.assign({}, this.serverParams, newProps);
      this.$store.commit("set", ["deposit_list_param", serverParams]);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    async firstLoad() {
      this.updateParams({
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        perPage: 10,
      });
      await this.loadItems();
    },

    async loadItems() {
      try {
        let response = await axios.get("/deposit", {
          params: this.$store.state.deposit_list_param,
        });
        this.totalRecords = response.data.data.meta.total;
        this.$store.commit("set", ["deposit_list", response.data.data.values]);
      } catch (error) {
        if (typeof error.response.data.message !== "undefined") {
          await this.$notify({
            type: "error",
            text: error.response.data.message,
          });
        }
        this.totalRecords = 0;
        this.$store.commit("set", ["deposit_list", []]);
      }
    },
  },
  async created() {
    await this.firstLoad();
  },
};
</script>
